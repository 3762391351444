<template>
    <div class="scan" >

        <el-form  :inline="true" :model='scanSearchForm'  :rules="scanSearchRules" ref="scanSearchForm" class="search-form">
            <el-form-item label="设备列表："  prop="deviceId">
                <el-select v-model="scanSearchForm.deviceId" filterable placeholder="请选择" style="min-width: 300px;" @change="deviceChange">
                    <el-option v-for="item in deviceList" :key="item.deviceID" :label="item.deviceName" :value="item.deviceID">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="考试列表：" prop="examId" >
                <el-select v-model="scanSearchForm.examId" filterable placeholder="请选择" style="min-width: 450px;" @change="examChange">
                    <el-option v-for="item in examList" :key="item.id" :label="item.name+'('+item.id+')'" :value="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="isInCefClient">
<!--                <el-popover placement="bottom" width="160" v-model="tipUpload" trigger="manual">-->
<!--                    <p>请上传图片</p>-->
<!--                    <div style="text-align: right; margin: 0">-->
<!--                        <el-button size="mini" type="text" @click="tipUpload = false">知道了</el-button>-->
<!--                    </div>-->
<!--                </el-popover>-->
                <el-button type="primary" size ="mini" icon="search" :disabled="!canScan" @click='startScan'>扫描</el-button>
            </el-form-item>
<!--            <el-form-item v-if="isInCefClient">-->
<!--                <el-button type="primary" size ="mini" icon="search" :disabled="!canScan" @click='startScanAndUpload'>扫描并上传</el-button>-->
<!--            </el-form-item>-->
            <el-form-item v-if="isInCefClient">
                <el-popover placement="bottom" width="160" v-model="tipVisible" trigger="manual">
                    <p>扫描结束后，请通知服务人员处理异常</p>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="tipVisible = false">知道了</el-button>
                    </div>
                    <el-button type="success" size ="mini" icon="search" slot="reference" @click='wxSend'>通知服务人员</el-button>
                </el-popover>
            </el-form-item>
        </el-form>
        <el-alert v-if="scanSearchForm.examId != null && (!isBindCard )" center type="error" effect="dark">
            <div>
                <span>您尚未绑定答题卡</span> <el-button type="text" size ="mini" icon="search" @click='openBindExamDialog'>点击绑定</el-button>
            </div>
        </el-alert>
        <el-alert v-if="scanSearchForm.examId != null && (!isAnswer)" center type="error" effect="dark">
            <div>
                <span>您尚未设置答案</span> <el-button type="text" size ="mini" icon="search" @click='openNewExamTopicDialog'>点击设置</el-button>
            </div>
        </el-alert>
      <div >
        <el-alert  v-if="isInCefClient && scanSearchForm.examId != null && this.examCount.except>0" center
                   type="error"
                   effect="dark"><span style="margin-right: 10px">定位异常 {{this.examCount.except}} 张</span>
          <a :href="`#/scanPre?examId=${scanSearchForm.examId}`" target="_blank">查看详情</a>
        </el-alert>
      </div>

        <div @click="countAndSort">
            <el-alert  v-if="isInCefClient && scanSearchForm.examId != null" center style="background-color: #3087FF !important;"
                    :title="'已扫描' + imageList.length + '张试卷， 共上传' + (examCount.ocring+examCount.except+examCount.ocred) + '张'"  effect="dark">
            </el-alert>
        </div>
        <el-row v-if="isInCefClient && imageList.length > 0" style="margin-top: 15px;">

            <el-col :span="5" v-for="(item, index) in imageList" :key="item.fullName" :offset="(index % 4) > 0 ? 1 : 0" style="padding: 10px;">
                <el-card :body-style="{ padding: '0px' }">
                    <el-image class="image" fit="contain"
                              :src="'data:image/png;base64,'+item.imgStr" :preview-src-list="['data:image/png;base64,'+item.imgStr]">
                    </el-image>
                    <div style="padding: 14px;">
                        <span>{{item.name}}</span>
                        <div class="bottom clearfix">
                            <time class="time" v-if="item.status=='00'" style="color: red;">未上传</time>
                            <time class="time" v-if="item.status=='01'">已上传</time>
<!--                            <el-button type="danger"  size="mini" class="button" @click="deleteImage(index)">删除</el-button>-->
                        </div>
                    </div>
                </el-card>
            </el-col>


        </el-row>
        <el-row v-if="isInCefClient && imageList.length > 0" style="margin-top: 30px;">
            <el-col :span="12" v-if="!isAutoUpload" :offset="6">
                <el-button type="primary" icon="el-icon-top" :loading="isUploading" @click='startUpload'>上传</el-button>
            </el-col>

        </el-row>
        <bindExamDialog ref="bindExamDialog" :bindExam="selExam" @ok="bindExamOK"></bindExamDialog>
        <newExamTopicDialog ref="newExamTopicDialog" :newExamTopic="selExam" @ok="topicOK"></newExamTopicDialog>
<!--        <el-row >-->
<!--            <el-col :span="8" >-->
<!--                <el-card :body-style="{ padding: '0px' }">-->
<!--                    <el-image class="image" fit="contain"-->
<!--                              src="http://www.51obe.com/public/0.png">-->
<!--                    </el-image>-->
<!--                    <div style="padding: 14px;">-->
<!--                        <span>oo.png</span>-->
<!--                        <div class="bottom clearfix">-->
<!--                            &lt;!&ndash;                            <time class="time">{{ currentDate }}</time>&ndash;&gt;-->
<!--                            <el-button type="danger"  size="mini" class="button">删除</el-button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-card>-->
<!--            </el-col>-->
<!--        </el-row>-->
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import newExamTopicDialog from "../exam/newExam/newExamTopicDialog";
    import bindExamDialog from "../exam/newExam/bindExamDialog";
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'scan',
        data(){
            return {
                tableData: [], tableHeight:0, myStyle: null, iframeHeight: 100,
                loading:true, isInCefClient:false, canScan: false, tipVisible: false, tipUpload: false,
                isTwoPage: false, isScanContinue: false, isUploading: false, isAutoUpload: false,
                deviceList: [], imageList: [],
                examList: [], selExam: null, isBindCard: true, isAnswer: true,
                examCount: {ocring: 0, except: 0, ocred: 0},
                img64: '',
                deviceInfo: null, batchId: null, autoUploadCount: 0, oldImageCount: 0,
                scanSearchForm:{ deviceId: null, examId: null, scanContinue: 0, scanCount: 1},
                scanSearchRules: {
                    deviceId: [{ required: true, message: '请选择扫描仪', trigger: 'blur' }],
                    examId: [{ required: true, message: '请选择试卷', trigger: 'blur' }],
                },
            }
        },
        components:{
            Pagination, newExamTopicDialog, bindExamDialog
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        timers: {
            autoUpload: {time: 1000, autostart: false, repeat: true},
        },
        created() {
            window.cef2Web = this.cef2Web;
            window.cef2Image = this.cef2Image;
            window.scanning = this.scanning;
            window.scanCancel = this.scanCancel;
            window.scanComplete = this.scanComplete;
            window.uploading = this.uploading;
            window.uploadSuccess = this.uploadSuccess;
            window.uploadComplete = this.uploadComplete;
            window.uploadFail = this.uploadFail;
        },
      	mounted() {
            if(typeof scanManager == 'undefined'){
                this.isInCefClient = false;
            }else{
                this.isInCefClient = true;
                let s = JSON.stringify({key: "init"});
                console.log("exec", s)
                let respStr = twainManager.exec(s);
                console.log("resp", respStr)
                this.loadDeviceList();
                let that = this;
                setTimeout(function () {
                    that.tipVisible = true;
                }, 1000);
            }
            this.loadExamList();

            setInterval(() => {
                this.loadScanCount()
            }, 5000);
	   },
        methods: {

            countAndSort(){
                this.loadScanCount();
                // this.imageList = XEUtils.sortBy(this.imageList, 'status').reverse()
            },
            loadDeviceList(){
                if(this.isInCefClient){
                    let j = {key: "deviceInfo"};
                    let s = JSON.stringify(j);
                    console.log("exec", s)
                    let respStr = twainManager.exec(s);
                    if(respStr && respStr != ''){
                        let resp = JSON.parse(respStr);
                        if(resp.list){
                            this.deviceList = resp.list;
                            this.deviceList.unshift({deviceID: null, deviceName: '无'})
                            if(this.deviceList.length == 1){
                                this.scanSearchForm.deviceId = this.deviceList[0].deviceID;
                                this.canScan = true;
                            }
                        }
                    }
                    console.log("resp", respStr)
                }
            },
            deviceChange(){
                this.canScan = true;
                if(this.isInCefClient){
                    let deviceName = "test";
                    let deviceId = parseInt(this.scanSearchForm.deviceId);
                    let ar = XEUtils.find(this.deviceList, item => {
                        return item.deviceID === deviceId;
                    });
                    if(ar){
                        deviceName = ar.deviceName;
                    }
                    console.log("deviceName", deviceName);
                    let s = JSON.stringify({key: "loadSourceCaps", deviceId: deviceId, deviceName: deviceName});
                    console.log("exec", s)
                    let respStr = twainManager.exec(s);
                    console.log("resp", respStr)
                }
            },
            loadScanCount(){
              if(this.scanSearchForm.examId == null) return;
                let search = { licenceId: this.userInfo.licenceId, examId: this.scanSearchForm.examId};
                let param = { controllerName: 'scan/scanPre', methodName: '/scanCount', param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    this.examCount = {ocring: 0, except: 0, ocred: 0}
                    if (data) {
                        for (let i=0; i<data.length; i++){
                            this.examCount.ocring = this.examCount.ocring + data[i].status_count;
                            if(data[i].status === "Y5"){
                              this.examCount.except = this.examCount.except + data[i].status_count;
                              this.$set(this.examCount, 'except', this.examCount.except);
                            }
                        }

                    }


                }).catch((error) => {
                    console.log("error")
                });
            },
            startScan(){
                let that = this;
                this.$refs.scanSearchForm.validate((valid) => {
                    if (valid) {
                        let deviceId = mUtils.searchText(that.scanSearchForm.deviceId)
                        if(that.isInCefClient && deviceId != null){
                            that.canScan = false;
                            that.imageList = [];
                            let examId = parseInt(that.scanSearchForm.examId);
                            let examFind = XEUtils.find(that.examList, item => item.id == examId);
                            let examName = XEUtils.trim(examFind?examFind.name:examId);
                            let deviceFind = XEUtils.find(that.deviceList, item => item.deviceID == deviceId);
                            let deviceName = (deviceFind?deviceFind.deviceName:deviceId);
                            let j = {key: "startScan", token: that.userInfo.token, userName: that.userInfo.nickName,
                                licenceKey: that.userInfo.licenceKey, licenceName: that.userInfo.licenceName,
                                examId: parseInt(this.scanSearchForm.examId), examName: examName,
                                deviceName: deviceName, deviceId: deviceId};

                            let respStr = twainManager.exec(JSON.stringify(j));
                            that.isAutoUpload = false;
                            that.batchId = XEUtils.toDateString(new Date(), 'yyyyMMddHHmmssSSS');
                            console.log("resp", respStr);
                        }
                    }
                });
            },
            startScanAndUpload(){
                let that = this;
                this.$refs.scanSearchForm.validate((valid) => {
                    if (valid) {
                        let deviceId = mUtils.searchText(that.scanSearchForm.deviceId)
                        if(that.isInCefClient && deviceId != null){
                            that.canScan = false;
                            that.imageList = [];
                            let examId = parseInt(that.scanSearchForm.examId);
                            let examFind = XEUtils.find(that.examList, item => item.id == examId);
                            let examName = (examFind?examFind.name:examId);
                            let deviceFind = XEUtils.find(that.deviceList, item => item.deviceID == deviceId);
                            let deviceName = (deviceFind?deviceFind.deviceName:deviceId);
                            let j = {key: "startScan", token: that.userInfo.token, userName: that.userInfo.nickName,
                                licenceKey: that.userInfo.licenceKey, licenceName: that.userInfo.licenceName,
                                examId: parseInt(this.scanSearchForm.examId), examName: examName,
                                deviceName: deviceName, deviceId: deviceId};

                            let respStr = twainManager.exec(JSON.stringify(j));
                            console.log("resp", respStr);
                            this.isAutoUpload = true;
                            this.autoUploadCount = 0;
                            this.oldImageCount = 0;
                            if(!this.timers.autoUpload.isRunning) {
                                this.$timer.start('autoUpload');
                            }
                        }
                    }
                });

            },
            autoUpload(){
                if(this.isAutoUpload && this.isInCefClient){
                    if(this.autoUploadCount > 3){
                        console.log("autoUpload startUpload");
                        this.startUpload();
                        this.$timer.stop('autoUpload');
                        this.autoUploadCount = 0;
                    }
                    if(this.imageList && this.imageList.length > 0){
                        if(this.oldImageCount != this.imageList.length){
                            this.oldImageCount = this.imageList.length;
                            this.autoUploadCount = 0;
                        }else{
                            this.autoUploadCount = this.autoUploadCount + 1;
                        }
                    }
                    console.log("autoUpload", this.autoUploadCount);
                }else{
                    this.autoUploadCount = 0;
                }
            },
            loadExamList(){
                let search = { licenceId: this.userInfo.licenceId, schoolId: null, teacherId: null, scan: 'Y'};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["scanTeacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'exam', methodName: '/examList', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            examChange(v){
                console.log('examChange', v);
                let ar = XEUtils.find(this.examList, item => {
                    return item.id == v;
                })
                if(ar){
                    this.isBindCard = ar.exam_sheet_count>0?true:false;
                    this.isAnswer = ar.topic_answer_count > 0?false:true;
                }

                this.loadScanCount()
            },
            openBindExamDialog(row){
                let v = this.scanSearchForm.examId;
                let ar = XEUtils.find(this.examList, item => {
                    return item.id == v;
                })
                if(ar){
                    this.selExam = Object.assign(ar,{ time: (new Date()).getMilliseconds()});
                }
                this.$refs.bindExamDialog.showDialog();
            },
            openNewExamTopicDialog(){
                let v = this.scanSearchForm.examId;
                let ar = XEUtils.find(this.examList, item => {
                    return item.id == v;
                })
                if(ar){
                    this.selExam = Object.assign({time: new Date().getMilliseconds(), randomNo: Math.round(Math.random()*50000) }, ar);
                    console.log("openNewExamTopicDialog", this.selExam);
                }
                this.$refs.newExamTopicDialog.showDialog();
            },
            bindExamOK(){
                this.scanSearchForm.examId = null;
                this.isBindCard = true;
                this.loadExamList();
            },
            topicOK(){
                this.scanSearchForm.examId = null;
                this.isAnswer = true;
                this.loadExamList();
            },
            scanning(index, fullName, imgStr, deviceInfo, ossName){
                // this.imageList.unshift({name: ""+index, index: index, fullName: fullName, status: '00', imgStr: imgStr, deviceInfo: deviceInfo, ossName: ossName});
                this.imageList.push({name: ""+index, index: index, fullName: fullName, status: '00', imgStr: imgStr, deviceInfo: deviceInfo, ossName: ossName});
                this.deviceInfo = deviceInfo;
                this.canScan = false;
                console.log("scanning", index, fullName);
            },
            scanCancel(){
                this.canScan = true;
                console.log("scanCancel");
            },
            scanComplete(scanImageCount){
                this.tipUpload = true;
                this.canScan = false;
                this.$alert("共扫描"+ scanImageCount + "张图片，请上传图片");
            },
            uploading(imageCount, imageIndex, ossName){
                console.log('uploading', imageCount, imageIndex, ossName)
                this.$notify({
                    title: '上传试卷',
                    message: ossName + '上传中，' + imageIndex + "/" + imageCount,
                    type: 'success'
                });

            },
            uploadSuccess(ossName, fileName, deviceInfo, seq, batchId){
                let that = this;
                let examId = parseInt(this.scanSearchForm.examId);
                let p = {licenceId: this.userInfo.licenceId, examId: examId, seq: seq, schoolId: this.userInfo.schoolId,
                    crur: this.userInfo.id, crurType: this.userInfo.role, batchId: this.batchId, status: '00', driveInfo: deviceInfo,
                    driveLocalName: fileName, ossName: ossName
                };
                console.log('uploadSuccess', ossName, fileName, deviceInfo, seq, batchId);
                let param = { controllerName: 'scan/scanPre', methodName: '/saveOrUpdate', param: p};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('uploadSuccess', code);
                    if (code == 0) {
                        let ar = XEUtils.find(that.imageList, item => {
                            return item.index == seq;
                        });
                        if(ar){
                            ar.status = '01';
                        }
                    }
                });

            },
            deleteImage(index){
                if(this.isInCefClient){
                    let that = this;
                    this.$confirm('此操作将永久删除扫描件, 是否继续?', '提示', {
                        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                    }).then(() => {
                        let delImg = that.imageList[index];
                        let j = {key: "deleteImage", index: delImg.index };
                        let respStr = twainManager.exec(JSON.stringify(j));
                        that.imageList.splice(index,1);
                        console.log("resp", respStr);
                    }).catch(() => {

                    });


                }
            },
            startUpload(){
                if(this.isInCefClient){

                    let s = JSON.stringify({key: "startUpload"});
                    console.log("exec", s)
                    this.isUploading = true;
                    let respStr = twainManager.exec(s);
                    console.log("resp", respStr)
                }
            },
            wxSend(){
                let that = this;
                let param = { controllerName: 'wxTemplateLog', methodName: '/scanComplateLog', param: {examId: this.scanSearchForm.examId}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('testSend', code, data);
                    that.$alert('我们将尽快处理', '收到', {
                        confirmButtonText: '确定',
                    });
                });
            },
            uploadFail(){
                this.isUploading = false;
                this.$notify({title: '上传部分失败',
                    message: '部分试卷上传失败，请点击上传重新上传',
                    type: 'success'
                });
            },
            uploadComplete(){
                this.canScan = true;
                this.imageList = [];
                this.$notify({
                    title: '所有试卷',
                    message: '上传完成',
                    type: 'success'
                });
                this.loadScanCount();
                this.isUploading = false;
            },
            cef2Web(msg){
                this.$message(msg);
            },
            cef2Image(s){
                this.img64 = s;
            }

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .scan{
        .search_container{

        }
        .btnRight{
            float: right;
            margin-right: 0px !important;
        }
        .searchArea, .search-form{
            background:rgba(255,255,255,1);
            border-radius:2px;
            padding: 18px 18px 0;
        }
        .table_container{
            padding: 10px;
            background: #fff;
            border-radius: 2px;
        }
        .el-dialog--small{
            width: 600px !important;
        }
        .pagination{
            text-align: left;
            margin-top: 10px;
        }
        .time {
            font-size: 13px;
            color: #999;
        }

        .bottom {
            margin-top: 13px;
            line-height: 12px;
        }

        .button {
            padding: 0;
            float: right;
        }

        .image {
            width: 100%;
            height: 300px;
            display: block;
        }

        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }

        .clearfix:after {
            clear: both
        }
    }


</style>


